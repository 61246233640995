import React from "react";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const Owp = () => {
	const lang = "pl";

	return (
		<Layout
			seoTitle="Ogólne warunki przewozu"
			lang={lang}
			translationEN="/en/gtoc/"
			translationDE="/de/allgemeine-transportbedingungen/"
		>
			<SmallBanner title="Ogólne warunki przewozu" lang={lang} />

			<section className="default-main">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<p>
								Niniejsze Ogólne Warunki Przewozu zwane dalej
								OWP mają zastosowanie do umów przewozu towarów
								zawieranych pomiędzy Palettenwerk Kozik Sp. z
								o.o. lub Palko Sp. z o.o. zwanymi dalej
								Zleceniodawcą a Przewoźnikiem na podstawie
								zleceń transportowych składanych Przewoźnikowi
								przez Zleceniodawcę.
							</p>
							<p>1. Postanowienia ogólne</p>
							<ul>
								<li>
									Niniejsze OWP stanowią integralną część
									każdego zlecenia transportowego wystawionego
									przez Zleceniodawcę. Odstępstwo od
									stosowania niniejszych OWP wymaga formy
									pisemnej pod rygorem nieważności.
								</li>
								<li>
									Przyjęcie zlecenia transportowego do
									realizacji powinno zostać potwierdzone przez
									Przewoźnika za pomocą poczty elektronicznej
									na adres{" "}
									<a href="mailto:logistyka@palettenwerk.pl">
										logistyka@palettenwerk.pl
									</a>{" "}
									. Nie przesłanie na adres, o którym mowa w
									zdaniu poprzednim odmowy przyjęcia zlecenia
									transportowego w ciągu 30 minut od jego
									otrzymania uznaje się za przyjęcie przez
									Przewoźnika zlecenia transportowego do
									realizacji.
								</li>
								<li>
									Przyjęcie przez Przewoźnika zlecenia
									transportowego do realizacji oznacza
									zawarcie umowy przewozu na warunkach
									określonych w zleceniu transportowym oraz w
									niniejszych OWP.
								</li>
							</ul>
							<p>2. Oświadczenia i zapewnienia przewoźnika</p>
							<ul>
								<li>
									Przyjmując zlecenie transportowe do
									realizacji Przewoźnik oświadcza, że: a/
									spełnia wszystkie warunki i wymogi formalne
									świadczenia usług transportowych w
									transporcie krajowym i/lub zagranicznym, a
									działalność przewozową realizuje jako
									profesjonalista; b/ posiada ważne i aktualne
									ubezpieczenie odpowiedzialności cywilnej za
									szkody wyrządzone w przewożonym towarze z
									sumą gwarancyjna odpowiadającą co najmniej
									200 % wartości towaru przyjmowanego do
									przewozu; c/ pojazdy, które będzie
									podstawiał pod załadunek i którymi będzie
									realizował zlecenia transportowe spełniają
									wszystkie niezbędne warunki techniczne, w
									tym będą wyposażone we wszystkie niezbędne
									urządzenia zgodnie z obowiązującymi
									przepisami prawa; przedmiotowe pojazdy będą
									spełniały wymagane normy, będą sprawne pod
									względem technicznym, będą odpowiednie pod
									względem ładowności i wymagań odnośnie
									wysokości i długości określonych w zleceniu
									transportowym; będą czyste i bez obcych
									zapachów; przestrzeń ładunkowa będzie wolna
									od innych towarów oraz odpowiednio
									zabezpieczona przed możliwością uszkodzenia
									przesyłki i narażenia jej na niekorzystny
									wpływ środowiska podczas przewozu. d/ jako
									pojazd Mega+ rozumie pojazd o wysokości co
									najmniej 298 cm i długości 13,6 m; jako
									pojazd Mega rozumie pojazd o wysokości co
									najmniej 294 cm i długości 13,6 m; jako
									pojazd Standard rozumie pojazd o wysokości
									co najmniej 273 cm i długości 13,6 m; jako
									pojazd Zestaw rozumie pojazd o wysokościach
									naczepy i przyczepy co najmniej 298 cm i
									długościach naczepy i przyczepy 7,7 m.
								</li>
								<li>
									Przewoźnik obowiązany jest bezzwłocznie
									okazać, na każde żądanie Zleceniodawcy
									polisę potwierdzającą zawarcie umowy
									ubezpieczenia odpowiedzialności cywilnej, a
									także certyfikaty, licencje i inne dokumenty
									związane z przewozem. Zleceniodawcy
									przysługuje prawo sporządzenia kopii z
									okazanych dokumentów.
								</li>
							</ul>
							<p>3. Zasady realizacji przewozu</p>
							<ul>
								<li>
									Przewoźnik zobowiązany jest do podstawienia
									pojazdu do załadowania w terminie i w
									miejscu wynikającym ze zlecenia
									transportowego. Podstawienie pojazdu o
									parametrach niezgodnych ze zleceniem i/lub
									OWP uznaje się za niepodstawienie pojazdu.
								</li>
								<li>
									Przewoźnik zobowiązany jest do zapewnienia
									kierowcom schludnego i stosownego ubioru. Na
									terenie zakładu Zleceniobiorcy
									przewoźnik/jego kierowca obowiązani są do
									noszenia kamizelek odblaskowych, kasków
									ochronnych oraz butów ochronnych z
									podnoskiem metalowym lub kompozytowym.
								</li>
								<li>
									Na terenie zakładu Zleceniodawcy obowiązuje
									zakaz palenia tytoniu. Przewoźnik/jego
									kierowca obowiązani są do przestrzegania
									tego zakazu.
								</li>
								<li>
									W czasie oczekiwania przez Przewoźnika/jego
									kierowcę na załadunek na terenie
									Zleceniodawcy niedopuszczalnym jest, aby
									Przewoźnik/jego kierowca: a/ pozostawiał po
									sobie przedmioty, w tym śmieci, b/mył
									samochód na terenie należącym do
									Zleceniodawcy.
								</li>
								<li>
									Przewoźnik/jego kierowca nie mogą przewozić
									na naczepie innych przedmiotów i towarów niż
									objętych zleceniem transportowym.
								</li>
								<li>
									Przewoźnik zapewnia stałą łączność ze
									Zleceniodawcą i kierowcą wykonującym
									konkretną umowę przewozu.
								</li>
								<li>
									Przewoźnik/ jego kierowca ponoszą pełną
									odpowiedzialność za prawidłowy załadunek i
									rozmieszczenie towaru w przestrzeni
									załadunkowej, włączając w to prawidłowy
									nacisk na osie pojazdu.
								</li>
								<li>
									Zabronione jest pozostawianie załadowanego
									pojazdu na terenie zakładu Zleceniodawcy.
								</li>
								<li>
									Przewoźnik/jego kierowca mają obowiązek
									kontrolować przy załadunku zgodność
									pobranego towaru z dokumentami.
								</li>
								<li>
									W razie braku ładunku bądź jakichkolwiek
									innych problemów w miejscu załadunku
									Przewoźnik/jego kierowca nie mogą opuścić
									miejsca załadunku bez zgody ze strony
									Zleceniodawcy
								</li>
								<li>
									W razie jakichkolwiek problemów w miejscu
									rozładunku Przewoźnik/jego kierowca nie mogą
									opuścić miejsca rozładunku bez zgody
									Zleceniodawcy.
								</li>
								<li>
									Przewoźnik nie może powierzyć wykonania
									zlecenia transportowego innym podwykonawcom
									ani dokonać załadunku lub przeładunku na
									inny pojazd bez zgody Zleceniodawcy
								</li>
								<li>
									Przewoźnik jest zobowiązany do uzyskania od
									odbiorcy pokwitowania odbioru towaru na
									stosowanym w danym przypadku dokumencie ,
									potwierdzonego czytelnym podpisem osoby
									uprawnionej, pieczęcią firmową oraz
									osobistą. Na żądanie Zleceniodawcy
									Przewoźnik jest zobowiązany do dostarczenia
									dokumentu, o którym mowa w zdaniu poprzednim
									w ciągu 48h od rozładunku, dopuszczalna jest
									wersja elektroniczna przesłana na adres
									mailowy Zleceniodawcy.
								</li>
								<li>
									Przewoźnik zobowiązany jest do dostarczenia
									towaru dokładnie w terminie wskazanym na
									zleceniu transportowym. Przewoźnik przyjmuje
									do wiadomości fakt, że zlecenia transportowe
									są realizowane dla odbiorców stosujących
									rygorystyczne zasady dotyczące terminowości
									dostarczania towarów, w tym podstawiania
									samochodów do rozładunku o konkretnej
									godzinie
								</li>
								<li>
									Przewoźnik odpowiada za wszelkie szkody
									powstałe w związku z realizacją zlecenia
									transportowego, w szczególności za szkody w
									towarze powstałe od chwili przyjęcia do
									przewozu do chwili wydania, a także za
									szkody powstałe w związku nieprawidłowym
									wypełnieniem lub zagubieniem dokumentów,
									opóźnieniami w dostarczeniu towaru;
									całkowitą lub częściową utratą, uszkodzeniem
									lub ubytkiem towaru
								</li>
								<li>
									Nie wywiązanie się z jakichkolwiek warunków
									zlecenia transportowego i/lub OWP , a w
									szczególności opóźnienie/ niepodstawienie
									pojazdu do załadunku oraz nieterminowe
									dostarczenie towaru powoduje naliczenie
									przez Zleceniodawcę kary umownej w wysokości
									do 200 % ceny frachtu. Kara o której mowa w
									zdaniu poprzednim jest płatna w terminie 14
									dni licząc od dnia wystawienia przez
									Zleceniodawcę noty obciążeniowej.
									Zleceniodawca zastrzega sobie możliwość
									dochodzenia odszkodowania przewyższającego
									wysokość kary umownej.
								</li>
							</ul>
							<p>4. Rozliczanie usług</p>
							<ul>
								<li>
									Przewoźnik do faktury dołącza dokumenty
									przewozowe, w tym dokumenty potwierdzające
									dostawę , specyfikację ( delivery note) i
									zlecenie transportowe. Komplet dokumentów
									dostarcza w terminie nie dłuższym niż 7 dni
									od daty rozładunku towaru. Przesłanie
									kompletu prawidłowo wypełnionych dokumentów
									jest warunkiem płatności za usługę.
									Zleceniodawca zobowiązuje się do zapłaty w
									ciągu 45 dni od daty otrzymania kompletu
									prawidłowo wypełnionych dokumentów.
								</li>
								<li>
									Zleceniodawcy przysługuje prawo potrącenia z
									wynagrodzenia Przewoźnika wszelkich,
									wynikających z OWP kar umownych.
								</li>
								<li>
									Przewoźnikowi nie przysługuje dodatkowe
									wynagrodzenie z tytułu postoju przy
									załadunku do 24h i rozładunku do 24h w
									transporcie krajowym i do 48h w transporcie
									zagranicznym, licząc od upływu umówionego
									dnia/godziny załadunku/ rozładunku z
									wyłączeniem dni ustawowo wolnych od pracy.
									Wysokość opłat za dłuższe postoje musi być
									uzgodniona i pisemnie potwierdzona przez
									Zleceniodawcę pod rygorem nieważności.
								</li>
							</ul>
							<p>
								5. Dane osobowe przetwarzane przez Zleceniodawcę
							</p>
							<ul>
								<li>
									Administratorem danych osobowych
									przetwarzanych w związku ze zleceniami
									transportowymi jest Zleceniodawca - w
									zakresie wskazanym w punkt 5.2. poniżej
								</li>
								<li>
									Dane osobowe: Przewoźnika tj. strony umowy
									(podstawa przetwarzania -> art. 6 ust. 1
									lit. b) rodo), a także jego przedstawicieli
									oraz innych osób wykonujących umowę np.
									pracowników lub współpracowników – w tym
									kierowców uprawnionych do obioru i dostawy
									towaru- podstawa przetwarzania -> art. 6
									ust. 1 lit. f) rodo tj. tzw. uzasadniony
									interes administratora danych), będą
									przetwarzane w celu zawarcia oraz wykonania
									umowy przez Zleceniodawcę. Dane osobowe mogą
									być przetwarzane także do celu dochodzenia,
									ustalenia lub obrony przez roszczeniami
									związanymi z realizacją przedmiotowej umowy
									(podstawa przetwarzania -> art. 6 ust. 1
									lit. f) rodo tj. tzw. uzasadniony interes
									administratora danych). Dane osobowe mogą
									być wykorzystywane także w celu realizacji
									zobowiązań publicznoprawnych wynikających z
									przepisów prawa (art. 6 ust. 1 lit. c)
									rodo).
								</li>
								<li>
									Dane nie będą nikomu udostępniane, chyba, że
									będzie to niezbędne do wykonania umowy,
									której stroną jest Zleceniodawca (np. w
									zakresie wskazania danych kierowców
									odbierających lub dostarczających towar
									odbiorcy) lub wynikać to będzie z przepisów
									prawa.
								</li>
								<li>
									Zleceniodawca może powierzyć dane np.
									dostawcom usług informatycznych czy
									księgowych, czy przekazać korespondencję
									pomiędzy upoważnionymi pracownikami Spółki.
								</li>
								<li>
									Przysługuje Ci prawo dostępu do danych, w
									tym uzyskania kopii danych, prawo do
									przenoszenia danych, prawo do sprostowania i
									usunięcia danych, ograniczenia przetwarzania
									oraz prawo do zgłoszenia sprzeciwu (gdy
									przetwarzanie następuje na podstawie art. 6
									ust. 1 lit. f rodo). Przysługuje Ci prawo
									wniesienia skargi do organu nadzorczego
									(Prezesa Urzędu Ochrony Danych Osobowych).
								</li>
								<li>
									Dane osobowe zostaną usunięte lub
									zanonimizowane maksymalnie po upływie okresu
									przedawnienia potencjalnych roszczeń
									związanych z realizacją umowy, w
									szczególności wynikających z procesu
									zawierania i realizacji umowy zawartej przez
									Zleceniodawcę, zobowiązań publicznoprawnych
									lub krócej jeżeli zgłosisz skuteczny
									sprzeciw.
								</li>
								<li>
									Podanie danych jest dobrowolne ale nie
									zbędne do realizacji w/w celów.
								</li>
								<li>
									We wszelkich kwestiach dotyczących ochrony
									danych skontaktuj się z nami pisząc na adres
									Zleceniodawcy lub na adres mailowy:
									rodo@palettenwerk.pl Więcej informacji o
									przetwarzaniu Twoich danych znajduje się w
									Polityce prywatności dostępnej w zakładce
									Kontakt na stronie internetowej
									palettenwerk.pl
								</li>
								<li>
									Zważywszy, że dane osobowe Zleceniodawca
									może otrzymać zarówno bezpośrednio – w
									przypadku danych Przewoźnika, jak i
									pośrednio – w przypadku danych jego
									pracowników lub współpracowników
									(przetwarzanych w celach o których mowa
									powyżej), Przewoźnik zobowiązuje się
									niezwłocznie udostępnić kopię niniejszej
									klauzuli informacyjnej (ust. 1 – 8 powyżej)
									wszystkim osobom, których dane przekazuje
									Zleceniodawcy w ramach zleceń transportowych
									a także zobowiązuje się poinformować te
									osoby o zakresie udostępnianych danych
									osobowych.
								</li>
							</ul>
							<p>6. Postanowienia końcowe</p>
							<ul>
								<li>
									Wszelkie informacje zawarte w zleceniu
									transportowym jak i pozyskane przez
									Przewoźnika/jego kierowcę w trakcie
									realizacji umowy są poufne i stanowią
									tajemnicę przedsiębiorstwa Zleceniodawcy.
									Informacje te nie mogą być wykorzystywane i
									rozpowszechniane bez zgody Zleceniodawcy. W
									przypadku naruszenia przez Przewoźnika/jego
									kierowcę tajemnicy przedsiębiorstwa opisanej
									powyżej Przewoźnik zapłaci Zleceniodawcy
									karę umowną w wysokości 60 000 PLN. Kara o
									której mowa w zdaniu poprzednim jest płatna
									w terminie 14 dni licząc od dnia wystawienia
									przez Zleceniodawcę noty obciążeniowej.
									Jeżeli szkoda przekroczy wysokość kary
									umownej Zleceniodawca będzie uprawniony do
									dochodzenia odszkodowania na zasadach
									ogólnych.
								</li>
								<li>
									W sprawach nieuregulowanych w OWP i w
									zleceniu transportowym zastosowanie mają
									przepisy kodeksu cywilnego oraz prawa
									przewozowego i/lub konwencji CMR
								</li>
							</ul>
							<p>7. Sprawy sporne</p>
							<p>
								Sprawy sporne rozpatrywane będą według prawa
								polskiego i przez sąd właściwy dla siedziby
								Zleceniodawcy.
							</p>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Owp;
